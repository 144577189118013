.backgroundBox {
    background-image: url('./assets/images/login_new.svg');
    background-position: center center;
    background-size: 100%;
    background-repeat: no-repeat;
  }
  
  .loginBox {
    width: 650px;
    height: auto;
    min-height: 450px;
    padding: 60px;
    background-color: rgb(255, 255, 255);
    color: rgb(36, 48, 66);
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    border-radius: 2px;
  }
  
  .loginGreeting {
    font-size: 24px;
    padding-top: 40px;
    line-height: 32px;
    font-family: 'AvenirNextLTProDemi';
    font-weight: 600;
  }
  
  .loginSubGreeting {
    font-size: 14px;
    line-height: 22px;
    font-family: 'AvenirNextLTProDemi';
    font-weight: 500;
    color: rgb(140, 140, 140);
  }
  
  .loginFormLabel {
    padding-top: 24px;
    font-family: 'AvenirNextLTProDemi';
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  }
  
  .forgotLink {
    padding-top: 13px;
    font-size: 12px;
    line-height: 20px;
    font-family: 'AvenirNextLTProDemi';
    font-weight: 600;
  }
  
  .tosText {
    font-family: 'AvenirNextLTProDemi';
    font-weight: 400;
    line-height: 22px;
    color: rgb(140, 140, 140);
  }
  
  .tosLink {
    font-weight: 600;
    color: rgb(140, 140, 140);
  }
  
  .rightButton {
    float: right;
    margin-top: -5px;
  }
  
  .rightButtonFP {
    float: right;
    margin-top: 15px;
  }
  
  .leftButtonFP {
    float: left;
    margin-top: 15px;
    border: 1px solid #d9d9d9 !important;
  }
  
  .leftButton {
    float: left;
    margin-top: -5px;
  }
  
  .reverseButton .btn .btn-secondary .btn-sm {
    background: #ffffff !important;
    border: 1px solid #d9d9d9 !important;
    border-radius: 33px;
    color: #1a7cd3;
  }
  
  .fpButtons {
    padding-top: 35px;
  }
  
  .customIcon {
    position: absolute;
    left: auto;
    right: 10px;
    text-indent: 32px;
    top: 14px;
  }
  
  .bottomLinks {
    font-family: AvenirNextLTProDemi;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: #1a7cd3;
    text-align: center;
  }
  
  .error {
    color: #f5222d;
    font-family: AvenirNextLTProDemi;
    font-size: 14px;
    line-height: 22px;
  }
  
  .validations {
    font-family: AvenirNextLTProDemi;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #595959;
    padding-top: 17px;
  }
  