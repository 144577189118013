.login-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > .login-info-container {
      width: 568px;
      height: auto;
      padding: 64px 60px 69px 60px;
      background-color: #ffffff;
      color: rgb(36, 48, 66);
      box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
      border-radius: 2px;

      & > img.login-logo {
        width: 158px;
        margin-bottom: 40px;
      }

      & > h1 {
        font-family: "AvenirNextLTPro";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        color: #243042;
        margin: 0px;
        padding: 0px;
        margin-bottom: 8px;
      }

      & > h3 {
        margin: 0px;
        padding: 0px;
        font-family: "AvenirNextLTPro";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: #8C8C8C;
        margin-bottom: 24px;
      }

      & > form.login-form {
        display: flex;
        flex-direction: column;
        width: 100%;
        position: relative;

        & > label.login-form-label {
          display: flex;
          flex-direction: column;
          font-family: "AvenirNextLTPro";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 22px;
          color: #262626;
          margin-bottom: 16px;

          & > input.ant-input {
            font-family: "AvenirNextLTPro";
            font-size: 12px;
            font-weight: 400;

            &:-webkit-autofill::first-line,
            &:-webkit-autofill {
              font-family: "AvenirNextLTPro";
              font-size: 12px;
              font-weight: 400;
            }
          }
        }

        & > div.login-form-footer {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          & > p.login-form-tos {
            font-family: "AvenirNextLTPro";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            color: #8C8C8C;
            margin: 0px;
            padding: 0px;

            & > a.login-form-tos-link {
              font-weight: bolder;
              color: #8C8C8C;
              text-decoration: none;

              &:hover {
                text-decoration: underline;
                cursor: pointer;
              }
            }
          }
        }

        & > p.login-form-warning {
          margin: 0px;
          position: absolute;
          top: 0px;
          right: 0px;
          width: fit-content;
        }
      }

      & > form.forgotPassword-form {
        display: flex;
        flex-direction: column;
        width: 100%;
        position: relative;

        & > label.forgotPassword-form-label {
          display: flex;
          flex-direction: column;
          font-family: "AvenirNextLTPro";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 22px;
          color: #262626;
          margin-bottom: 24px;

          & > input.warning {
            border: 1px solid #F5222D;
          }
        }

        & > div.forgotPassword-form-footer {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          .ant-btn-default {
            border: 1px solid #D9D9D9;

            &:hover {
              & > span {
                text-decoration: underline;
              }
            }
          }
        }

        & > p.forgot-password-warning {
          margin: 0px;
          position: absolute;
          top: 55px;
          left: 0px;
          width: fit-content;
        }
      }
    }

    & > ul.login-footer {
      width: 336px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0px;
      margin: 0px;
      margin-top: 24px;
      list-style-type: none;
    }

  }

  .login-link-style {
    font-family: "AvenirNextLTPro";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .login-forgot-password {
    margin: 0px 16px;
    width: fit-content;
  }

  .login-warning {
    font-family: "AvenirNextLTPro";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #F5222D;
  }

.login-background {
    width: 100%;
    height: 100%;
    padding: 0px;
    margin: 0px;
}

.custom-login-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.loginBox {
    width: 568px;
    height: auto;
    min-height: 450px;
    padding: 60px;
    background-color: rgb(255, 255, 255);
    color: rgb(36, 48, 66);
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    border-radius: 2px;
}
.fpPadding {
    padding-top: 90px!important;
}

.loginGreeting {
    font-size: 24px;
    padding-top: 40px;
    line-height: 32px;
    font-family: "AvenirNextLTPro";
    font-weight: 600;
}

.loginSubGreeting {
    font-size: 14px;
    line-height: 22px;
    font-family: "AvenirNextLTPro";
    font-weight: 500;
    color: rgb(140, 140, 140);
}

.loginFormLabel {
    padding-top: 24px;
    font-family: "AvenirNextLTPro";
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
}

.forgotLink {
    padding-top: 13px;
    font-size: 12px;
    line-height: 20px;
    font-family: "AvenirNextLTPro";
    font-weight: 600;
}

.tosText {
    font-family: "AvenirNextLTPro";
    font-weight: 400;
    line-height: 22px;
    color: rgb(140, 140, 140);
}

.tosLink {
    font-weight: 600;
    color: rgb(140, 140, 140);
}

.rightButton {
    float: right;
    margin-top: -5px;
}

.rightButtonFP {
    float: right;
    margin-top: 15px;
}

.leftButtonFP {
    float: left;
    margin-top: 15px;
    border:1px solid #D9D9D9!important;
}

.leftButton {
    float: left;
    margin-top: -5px;
}

.reverseButton .btn .btn-secondary .btn-sm {
    background: #ffffff!important;
    border: 1px solid #d9d9d9!important;
    border-radius: 33px;
    color: #1a7cd3;
}

.fpButtons {
    padding-top: 35px;
}

.customIcon {
    position: absolute;
    left: auto;
    right: 10px;
    text-indent: 32px;
    top: 14px;
}

.bottomLinks {
    width: 200px;
    font-family: AvenirNextLTPro;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: #1A7CD3;
    text-align: center;
}

.error {
    color: #F5222D;
    font-family: AvenirNextLTPro;
    font-size: 14px;
    line-height: 22px;
}