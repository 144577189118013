@import '~antd/dist/antd.css';
@import url("../node_modules/bootstrap/dist/css/bootstrap.css");

@font-face {
  font-family: 'AvenirNextLTPro';
  src: url('assets/fonts/AvenirNextLTPro/AvenirNextLTPro-Regular.eot');
  /* IE9 Compat Modes */
  src: url('assets/fonts/AvenirNextLTPro/AvenirNextLTPro-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('assets/fonts/AvenirNextLTPro/AvenirNextLTPro-Regular.woff') format('woff'), /* Modern Browsers */
  url('assets/fonts/AvenirNextLTPro/AvenirNextLTPro-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('assets/fonts/AvenirNextLTPro/AvenirNextLTPro-Regular.svg#AvenirNextLTPro-Regular') format('svg');
  /* Legacy iOS */
}

@font-face {
  font-family: 'AvenirNextLTProBold';
  src: url('assets/fonts/AvenirNextLTProBold/AvenirNextLTPro-Bold.eot');
  /* IE9 Compat Modes */
  src: url('assets/fonts/AvenirNextLTProBold/AvenirNextLTPro-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('assets/fonts/AvenirNextLTProBold/AvenirNextLTPro-Bold.woff') format('woff'), /* Modern Browsers */
  url('assets/fonts/AvenirNextLTProBold/AvenirNextLTPro-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
  url('assets/fonts/AvenirNextLTProBold/AvenirNextLTPro-Bold.svg#AvenirNextLTPro-Bold') format('svg');
  /* Legacy iOS */
}

@font-face {
  font-family: 'AvenirNextLTProDemi';
  src: url('assets/fonts/AvenirNextLTProDemi/AvenirNextLTPro-Demi.eot');
  /* IE9 Compat Modes */
  src: url('assets/fonts/AvenirNextLTProDemi/AvenirNextLTPro-Demi.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('assets/fonts/AvenirNextLTProDemi/AvenirNextLTPro-Demi.woff') format('woff'), /* Modern Browsers */
  url('assets/fonts/AvenirNextLTProDemi/AvenirNextLTPro-Demi.ttf') format('truetype'), /* Safari, Android, iOS */
  url('assets/fonts/AvenirNextLTProDemi/AvenirNextLTPro-Demi.svg#AvenirNextLTPro-Demi') format('svg');
  /* Legacy iOS */
}


@font-face {
  font-family: 'LatoMedium';
  src: url('assets/fonts/Lato-Medium/Lato-Medium.eot');
  /* IE9 Compat Modes */
  src: url('assets/fonts/Lato-Medium/Lato-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('assets/fonts/Lato-Medium/Lato-Medium.woff') format('woff'), /* Modern Browsers */
  url('assets/fonts/Lato-Medium/Lato-Medium.ttf') format('truetype'), /* Safari, Android, iOS */
  url('assets/fonts/Lato-Medium/Lato-Medium.svg#Lato-Medium') format('svg');
  /* Legacy iOS */
}


* {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased !important;
}

html, body {
  height: 100vh;
  width: 100vw;
  overflow-y: hidden;
  position: relative;
  min-width: 1000px;
}


.temp-router-height {
  height: '100%';
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0,0,0,.5);
  box-shadow: 0 0 1px rgba(255,255,255,.5);
}

.title-25 {
  letter-spacing: 0.4px;
  color: #3f454d;
  font-size:30px;
  font-family: LatoMedium;
}

/* this can be removed once all the modals are updated to ant-design */
.ant-notification{
  z-index: 99999999999!important;
}


.ag-grid-grey{
  color: grey;
}



/* grid overrides */
#totalsBarGrid .ag-root-wrapper .ag-root-wrapper-body{
  background-color: #f1f3f5!important;
}

#totalsBarGrid .ag-root-wrapper{
  background-color: #f1f3f5!important;
  border: none;
}

#totalsBarGrid .ag-cell{
  background-color: #f1f3f5!important;
}


.ag-grid-icon {
  text-align: center;
  cursor: pointer;
  padding-right: 0!important;
  padding-left: 0!important;
}

.grid-center{
  text-align: center;
}

.grid-bold-blue{
  color: #0d7ad6;
}

.ag-header-group-cell::after  {
  display: none!important;
}

.ag-pinned-right-header .ag-header-cell::after {
  display: none!important;
}

/* filters */
.ag-popup-child{
  border: 1px solid #c4cacd!important;
}
.ag-popup-child .ag-menu-header{
  background-color: #f6f8f8;
  border-bottom: 1px solid #c4cacd;
}
.ag-popup-child .ag-menu-header .ag-tab-selected{
  border-top: 1px solid #c4cacd;
  border-right: 1px solid #c4cacd;
  border-left: 1px solid #c4cacd;
}
.ag-filter-body-wrapper {
  border-bottom: 1px solid #c4cacd;
}
.ag-filter-apply-panel .ag-standard-button{
  border: 1px solid #c4cacd!important
}
/* end filters */


.ag-cell-focus{
  border-top-color: transparent!important;
  border-bottom-color: transparent!important;
  border-right-color: transparent!important;
  background-color: rgba(0, 145, 234, 0.2)!important;
}
.ag-theme-balham-38 .ag-cell-range-selected-1:not(.ag-cell-focus) {
  background-color: rgba(0, 145, 234, 0.2) !important;
}
.ag-theme-balham-38 .ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell).ag-cell-range-right {
  border-right-color: transparent!important;
}

.ag-theme-balham-38 .ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell).ag-cell-range-bottom {
  border-bottom-color: transparent!important;
}

.ag-theme-balham-38 .ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell).ag-cell-range-top {
  border-top-color: transparent!important;
}

.ag-theme-balham-38 .ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell).ag-cell-range-left {
  border-left-color: transparent!important;
}

.grid-header-underline{
  border-bottom: 1px solid #0d7ad6;
}

.grid-header-week-number{
  font-style: normal;
  font-weight: 600;
  font-size: 8px;
  line-height: 100%;
}

.grid-12px{
  font-family: AvenirNextLTProDemi;
  font-size: 12px!important;
  padding-left: 5px;
}


/* start  level selectors */
/* we can set the group colors here based on the depth of the level */
.levels-1 .ag-row-level-0{
    background-color: #f1f3f6;
    color: #454f5e;
    font-family: AvenirNextLTProDemi;
}
.levels-1 .ag-row-level-0 .grid-bold-blue{
    font-weight: bold;
}



.levels-2 .ag-row-level-0{
    background-color: #cdd0d4;
    color: #454f5e;
    font-family: AvenirNextLTProDemi;
}
.levels-2 .ag-row-level-1{
    background-color: #f1f3f6;
    color: #454f5e;
    font-family: AvenirNextLTProDemi;
}
.levels-2 .ag-row-level-0 .grid-bold-blue{
    color: #454f5e;
}
.levels-2 .ag-row-level-1 .grid-bold-blue{
    color: #454f5e;
}
.levels-2 .ag-row-level-2 .grid-bold-blue{
    font-weight: bold;
}


.levels-3 .ag-row-level-0{
  background-color: #cdd0d4;
  color: #454f5e;
  font-family: AvenirNextLTProDemi;
}
.levels-3 .ag-row-level-1{
  background-color: #f1f3f6;
  color: #454f5e;
  font-family: AvenirNextLTProDemi;
}
.levels-3 .ag-row-level-2{
  background-color: #f1f3f6;
  color: #454f5e;
  font-family: AvenirNextLTProDemi;
}
.levels-3 .ag-row-level-0 .grid-bold-blue{
    color: #454f5e;
}
.levels-3 .ag-row-level-1 .grid-bold-blue{
    color: #454f5e;
}
.levels-3 .ag-row-level-2 .grid-bold-blue{
    color: #454f5e;
}

/* end grid overrides */






.impressions-input {
  width: 20% !important;
  border: none !important;
  text-align: center;
  font-size: 12px;
}

.is-invalid {
  border: 1px solid green!important;
}

.btn-sm-round[disabled=disabled], .btn-sm-round:disabled {
  background-color: #dedede!important;
  border: 1px solid #dedede!important;
}

.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.fast-spin {
  -webkit-animation: fa-spin 1s infinite linear;
  animation: fa-spin 1s infinite linear;
}

.custom-control-label {
  font-family: AvenirNextLTProDemi;
  font-size: 12px;
  padding-top: 4px;
}

.gutter-10.row {
  margin-right: -5px;
  margin-left: -5px;
}

.gutter-10>[class^="col-"], .gutter-10>[class^=" col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.group-text {
  font-family: AvenirNextLTPro;
  font-size: 10.5px;
  padding-top: 4px;
}

.group-wrapper {
  padding-right: 15px;
}

.group-before {
  padding-right: 10px;
}

.group-after {
  padding-left: 10px;
}

.filter-line {
  border-right: 1px solid #cdd0d4;
}

.icon-inside {
  position: absolute;
  right: 10px;
  top: calc(50% - 12px);
  font-size: 16px;
  font-size: 1.125rem;
  color: #c4c3c3;
  z-index: 3;
}

.login {
  height: 100%;
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

.bgCover {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding: 0;
}

.editor-input-full {
  border: none;
  border-bottom: 3px solid #0d6fd0;
  font-size: 11px;
  height: 28px;
  width: 70px;
  outline: none;
  text-align: center;
  font-weight: normal!important;
  color: #47505c;
}

.editor-input-full-width {
  border: none;
  border-bottom: 3px solid #0d6fd0;
  font-size: 11px;
  width: 100%;
  margin-left: 8px;
  outline: none;
  font-weight: normal!important;
  color: #47505c;
  padding-left: 4px;
}
.editor-input {
  border: 1px solid #cdd0d4;
  font-size: 13px;
  height: 25px;
  outline: none;
  text-align: center;
  font-weight: normal;
  color: #47505c;
}


.editor-input-order {
  border: 3px solid #cdd0d4;
  height: 28px;
  outline: none;
}



.scrollBox {
  height: 400px;
  overflow: scroll;
}

.dropdown-toggle::after {
  display: none;
}

.dayInWeek {
  color: #0d64ca;
  font-family: AvenirNextLTProDemi;
  padding-right: 2px;
}

.dayInWeekOff {
  font-family: AvenirNextLTProDemi;
  padding-right: 2px;
  color: #dcdee0;
}

.table thead th {
  border: 0px!important;
}

.hr-sm {
  margin-top: 4px;
  margin-bottom: 0;
}

.hide-options .Select-menu-outer {
  display: none;
}

.zone-header {
  font-family: AvenirNextLTProDemi;
  letter-spacing: 0.6px;
  background-color: #e7e9eb;
  font-size: 14px;
  padding-left: 32px!important;
}

.component-wrapper {
  margin-top: 80px;
}

/* globals */

.av10 {
  font-family: AvenirNextLTPro;
  letter-spacing: 0.6px;
  font-size: 10px;
}

.av20 {
  font-family: AvenirNextLTPro;
  letter-spacing: 0.6px;
  font-size: 20px;
}

.av15 {
  font-family: AvenirNextLTPro;
  letter-spacing: 0.6px;
  font-size: 15px;
}

.tiny {
  font-size: 9px;
}

.small {
  font-size: 10px;
}

.btn {
  letter-spacing: 0.6px;
  outline: none!important;
  box-shadow: none!important;
}

.btn-sm {
  font-size: 9px!important;
  padding: 8px 15px 8px 15px;
  font-weight: bold;
}


.btn-sm-round {
  border-radius: 20px;
}

.btn-sm-round:active {
  background-color: #0B64AF!important;
}

*, input, select, textarea, option, button {
  outline: none!important;
}

.btn-secondary {
  background-color: #0d7ad6!important;
  border-color: #0d7ad6!important;
}

.btn-secondary:hover {
  background-color: #118BF2!important;
  border: 1px solid #118BF2!important;
}

.btn-secondary:active {
  background-color: #0B64AF!important;
}

.btn-outline-secondary {
  border-color: #f2f4f6!important;
  color: #0d7ad6;
}

.btn-link {
  font-weight: bold;
  color: #0d6fd0;
  background-color: rgba(0,0,0,0);
}
.btn-link:active {
  background-color: rgba(0,0,0,0) !important;
}

.btn-link-normal {
  font-weight: normal;
  font-size: 10.5px !important;
  color: #0d6fd0;
  border-color: transparent;
  box-shadow: none;
}

.btn-link-normal:hover {
  font-size: 10.5px !important;
  color: #318dde !important;
  background: transparent !important;
}


.btn-primary {
  background-color: #0d6fd0;
}

.form-control-sm {
  font-size: 12px;
  padding-top: 6px;
  padding-bottom: 5px;
}

.modal-content {
  border-radius: 0!important;
  box-shadow: 0 4px 16px 0 rgba(36, 48, 66, 0.2);
  border: none;
}

.modal-lg {
  width: 650px!important;
}

.modal-title {
  font-family: 'AvenirNextLTPro';
  color: #323c4a;
  font-size: 25px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.modal-xl {
  width: 920px!important;
}

.modal-xl-m {
  width: 1000px!important;
}

.modal-backdrop {
  background-image: url("assets/themes/crystal.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.modal-backdrop.show {
  background-color: #464f5d;
  opacity: 0.7;
}

.dropdown-menu {
  border-radius: 0!important;
  box-shadow: 0 4px 16px 0 rgba(36, 48, 66, 0.2);
  border: none;
}

/* form overrides */

.form-control {
  border: 1px solid #D3D6D9!important;
  border-radius: 3px!important;
}

.form-control::-moz-placeholder {
  color: #A6A8AC!important;
  font-style: italic;
  font-weight: 100;
}

.form-control:-ms-input-placeholder {
  color: #A6A8AC!important;
  font-style: italic;
  font-weight: 100;
}

.form-control::-webkit-input-placeholder {
  color: #A6A8AC!important;
  font-style: italic;
  font-weight: 100;
}

.form-control:focus {
  box-shadow: 0 4px 16px 0 rgba(36, 48, 66, 0.2);
  border: 1px solid white!important;
}

.link {
  cursor: pointer;
}

.form-label {
  font-size: 12px;
  font-family: 'AvenirNextLTProDemi';
}

.form-value {
  font-size: 12px;
  font-family: 'AvenirNextLTPro';
}

.form-label-lg {
  color: #20293a;
  font-weight: lighter;
}

.text-sm {
  font-size: 12px;
  font-family: 'AvenirNextLTPro';
}

.form-group {
  margin-bottom: 15px;
}

#root {
  height: 100%;
  width: 100%;
}

.text-sub {
  color: #20293a;
  font-family: 'AvenirNextLTPro';
}

.color-blue {
  color: #0d7ad6;
}
.color-orange {
  color: #ff9518;
}
.color-light-grey {
  color: #cdd0d4;
}




/* title */

.title-lg {
  letter-spacing: 0.4px;
  color: #20293a;
  font-size: 30px;
  font-family: AvenirNextLTPro;
}

.title-bold {
  font-family: AvenirNextLTProBold;
  letter-spacing: 0.8px!important;
  font-size: 25px;
  color: #20293a;
}

.text-heading {
  font-family: AvenirNextLTPro;
  letter-spacing: 0.8px!important;
  font-size: 16px;
  color: #212839;
}

.text-value {
  font-family: AvenirNextLTProDemi;
  letter-spacing: 0.8px!important;
  font-size: 22px;
  color: #212839;
}

.flex-r {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

/* toolbar title */

.toolbar .title {
  letter-spacing: 0.4px;
  color: #3f454d;
  font-size: 33px;
  padding: 0;
  margin: 0;
  font-family: AvenirNextLTPro;
}

.long-text-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 700px;
}

.toolbarline {
  border-bottom: 1px solid #d5d8da;
}

.filterbar {
  border-bottom: 1px solid #d5d8da;
  padding-top: 10px;
  padding-bottom: 10px;
}

.filterbar .title {
  letter-spacing: 0.4px;
  color: #3f454d;
  text-transform: capitalize;
  font-size: 33px;
  padding: 0;
  margin: 0;
  font-family: AvenirNextLTPro;
}

.toolbarmicro {
  letter-spacing: 0.4px;
  text-transform: capitalize;
  font-family: AvenirNextLTPro;
  border-bottom: 1px solid #d6d8da;
}

.DayPickerInput input {
  padding: 5px 0px 5px 10px;
  border-radius: 4px;
  border: 1px solid #cdd0d4;
  width: 160px;
}



.avenirNextLTProBold {
  font-family: 'AvenirNextLTProBold';
}

.ag-theme-balham-60 .ag-row-selected {
  background-color:#d4e9fb !important;
}

.nav-secondary .nav-item {
  padding-left: 15px;
  padding-right: 25px;
}

.nav-secondary .nav-link {
  font-family: AvenirNextLTProBold;
  letter-spacing: 0.8px!important;
  font-size: 10px;
  color: #20293a;
  padding-bottom: 10px!important;
}

.nav-secondary .nav-active {
  border-bottom: 4px solid #0d6fd0;
}

.cell-editor {
  width: 100%;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  padding: 3px;
}

.select__indicators {
  display: none!important;
}

@media screen and (max-height: 715px) {
  .flight-adjust.dropdown-menu.show {
    top: 69px!important;
  }
}



.badge-sm {
  font-size: 9px;
  padding-bottom: 1px;
  padding-right: 7px;
  padding-left: 7px;
}

.badge-button {
  font-size: 9px;
  padding-bottom: 2px;
  padding-right: 3px;
  padding-left: 3px;
  padding-top: 1px;
}

.badge-round {
  font-size: 9px;
  padding-bottom: 1px;
  padding-right: 7px;
  padding-left: 7px;
  border-radius: 10px;
}

.disabled-icon {
  opacity: 0.38;
  cursor: default;
}

#page-router {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}